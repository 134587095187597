import React from 'react'

import MonthlyPlansCard from './Plans/_MonthlyPlansCard'
import dataJson from './assets/data/data.json'
import { ContentHeader, Carousel } from './style'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import useWidth from 'src/hooks/window/useWidth'

type MonthlyPlansType = {
  bestSeller: boolean;
  totalGb: string;
  gbSum: string;
  monthlyPrice: string;
  cashbackValue: string;
  portabilityGbBonus: number;
}

type ChoosePlanType = {
  oneLink: string;
}

export const EscolhaPlano = ({ oneLink }: ChoosePlanType) => {
  const data: typeof dataJson = dataJson
  const windowWidth = useWidth(300)

  return (
    <ContentHeader id='conheca-nossos-planos' className='py-5 pt-4 pt-md-5 d-flex justify-content-between'>
      <div className='container px-4 px-md-5 px-xl-0'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-xl-10 offset-xl-1 text-center mb-0'>
            <span className='fs-24 lh-30 fs-md-48 lh-md-60 fw-600 text-grayscale--500 sora'>Escolha seu plano</span>
            <span className='fs-16 lh-19 fs-md-18 lh-md-22 fw-400 text-grayscale--400 d-block mt-1 mt-md-2 col-lg-10 col-xl-12 px-lg-4 mx-auto'>
              Garanta bônus de internet e navegue no Inter e no WhatsApp sem gastar seus dados. Tenha ainda <span className='fw-700'>ligações ilimitadas</span> e <span className='fw-700'>bônus adicionais</span> em todos os planos. E tudo isso <span className='fw-700'>sem fidelidade!</span> Aproveite!
            </span>
          </div>
        </div>
        {windowWidth === 1024 ? <div className='row align-items-center align-items-md-end justify-content-between justify-content-lg-start'>
          <div className='col-12 col-xl-10 offset-xl-1'>
            <DefaultCarousel
              lg={{ items: 3 }}
            >
              {data.map((data: MonthlyPlansType) => (
                <div className='col-12 text-center' key={data.totalGb}>
                  <MonthlyPlansCard cardData={data} oneLink={oneLink} />
                </div>
                ))}
            </DefaultCarousel>
          </div>
        </div> :
        <Carousel className='row align-items-center align-items-md-end justify-content-between justify-content-lg-start'>
          {data.map((data: MonthlyPlansType) => (
            <div className='col-12 col-md-6 col-xl-3 text-center' key={data.totalGb}>
              <MonthlyPlansCard cardData={data} oneLink={oneLink} />
            </div>
            ))}
        </Carousel>}
        <div className='col-12 text-center mt-5 mt-md-3 mt-lg-5 px-0'>
          <span className='lh-17 fs-14 text-grayscale--500 fw-400 d-block'>*O bônus de portabilidade não é válido para linhas da Vivo e de suas operadoras móveis virtuais.</span>
        </div>
      </div>
    </ContentHeader>
  )
}
