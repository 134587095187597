
import React from 'react'
import { SectionWrapper } from './style'

import FaqWithTab from 'src/components/FaqWithTab/index'

import { FaqTabType } from 'src/components/FaqWithTab/types'

type FaqPropsType = {
  faq: FaqTabType[];
}

const FAQComponent = ({ faq }: FaqPropsType) => {
  return (
    <SectionWrapper>
      <div className='container py-5 my-xl-5'>
        <div className='row'>
          <div className='mb-4'>
            <h2 className='fs-24 lh-30 fs-md-40 lh-md-50 text-grayscale--400'>
              Perguntas frequentes
            </h2>
          </div>
          <FaqWithTab
            answerData={faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='px-0 col-12'
          />
          <div className='col-12'>
            <p className='pt-4 fs-10 lh-12 text-grayscale--500'>
              Credenciada de Rede Virtual, nos termos da Resolução da Anatel nº 550/2010. Serviço de telefonia móvel
              prestado pela Vivo.
            </p>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default FAQComponent
