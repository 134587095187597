import React from 'react'

const SecondStep = () => {
  return (
    <>
      <span className='fw-600 fs-24 lh-30 text-grayscale--500 mb-4 d-block sora'>
        2 - Solicite a portabilidade
      </span>
      <span className='text-grayscale--500 fs-18 lh-22 fs-lg-18 lh-lg-22 fw-400 mr-n1 mb-4 d-block'>
        Leia atentamente as instruções na tela e os Termos e Condições de Uso. Depois, selecione o botão
        <span className='fw-700'> “Solicitar portabilidade”. </span>
      </span>
    </>
  )
}

export default SecondStep
