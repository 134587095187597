import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import ControleDesktopLG from '../../assets/images/voce-controle-desktop-lg.png'
import ControleDesktopXL from '../../assets/images/voce-controle-desktop-xl.png'

export const SectionWrapper = styled.section`
  @media ${device.desktopLG} {
    background: white url(${ControleDesktopLG}) no-repeat center right 20px;
  }
  @media ${device.desktopXL} {
    background: white url(${ControleDesktopXL}) no-repeat center right 120px;
  }

  .list {
    display: flex;
  }
  .sora {
    font-family: 'Sora'
  }
`
export const Card = styled.div`
  background: white;
  min-height: 202px;
  min-width: 276px;
  box-shadow: 0px 4px 40px rgba(182, 172, 164, 0.25);
  border-radius: 8px;
  padding: 52px 40px 27px 27px;

  @media ${device.tablet} {
    min-height: 220px;
    min-width: 210px;
    padding: 27px;
    padding-top: 42px;
    padding-right: 22px;
  }

  @media ${device.desktopLG} {
    min-width: 214px;
  }
`
