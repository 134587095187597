import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Tooltip = styled.div` 
  background: ${grayscale['500']};
  border-radius: 8px;
  position: absolute;
  width: 279px;
  left: -32px;
  z-index: 8;
  padding: 20px;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.07);
  top: 26px;
  
  @media ${device.tablet} {
    left: -32px;
    top: 30px;
  }
  
  @media ${device.desktopLG} {
    left: -37px;
    top: 29px;
  }

  &:before {
    content: '';
    background: ${grayscale['500']};
    border-radius: 2px;
    height: 16px;
    position: absolute;
    top: -4px;
    left: 253px;
    width: 16px;
    transform: rotate(45deg);
    
    @media ${device.tablet} {
      left: 253px;
    }

    @media ${device.desktopLG} {
      left: 91%;
    }
  }
`

export const ButtonToolTip = styled.span`

  @media ${device.desktopLG} {
    margin-left: 5px;
  }
`
