import React from 'react'
import ScrollTo from 'src/components/ScrollTo'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'

const Hero = () => {
  return (
    <Section className='pt-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-5 col-lg-5 col-lx-6 order-md-last mb-4 mb-md-0'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/herobanner-plano-de-celular/image.webp'
              altDescription='Ilustralção 5gb Intercel'
              arrayNumbers={[ 312, 312, 456, 456 ]}
              className='float-md-right'
            />
          </div>
          <div className='col-12 col-md-7 col-lg-6 col-xl-5 pr-lg-0 pr-xl-3'>
            <h1 className='fs-26 lh-32 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4'>Plano de celular do Inter: faça portabilidade e ganhe até 5GB/mês por 1 ano</h1>
            <p className='fs-18 lh-22 text-grayscale--400'>Aproveite <span className='fw-700'>cashback de até 7%</span> direto na sua conta e todas as vantagens de ser Inter.</p>
            <p className='fs-18 lh-22 text-grayscale--400 mb-4 pb-md-5 pb-lg-0'>E mais: ao fazer a portabilidade e renovar seu plano mensalmente, você ganha até 5GB de bônus por 12 meses.</p>
            <ScrollTo
              to='#conheca-nossos-planos'
              section='dobra_o1'
              sectionName='Plano de celular do Inter: faça portabilidade e ganhe até 5GB/mês por 1 ano'
              elementName='Conhecer os planos'
            >
              <button className='btn btn-orange--extra fs-14 lh-17 fw-600 rounded-2 mx-auto text-white'>Conhecer os planos</button>
            </ScrollTo>
            <p className='fs-12 lh-15 text-grayscale--400 mt-3'>¹ Com exceção de números da Vivo e de suas operadoras móveis virtuais.</p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
