import React from 'react'
import ImageWebp from 'src/components/ImageWebp'

import { Container } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Cobertura = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Container className='py-5 mt-md-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6'>
            <div>
              <h2 className='fs-24 lh-30 fw-600 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 mb-4 whiteMD'>
                Cobertura Vivo
              </h2>
              <p className='fs-14 lh-17 fs-md-18 lh-md-22 fw-400 text-grayscale--400 mb-4 whiteMD'>
                A <span className='fw-700'>operadora de celular do Inter</span> usa a mesma infraestrutura de cobertura da Vivo. Ou seja, aqui você conta com a <span className='fw-700'>Rede Móvel Vivo premiada como “Best in Test” no Brasil pela empresa independente umlaut.</span>
              </p>
            </div>
            <div>
              <a
                href='https://mapadecobertura.vivo.com.br/'
                title='Visitar o site da Vivo'
                target='_blank'
                rel='noreferrer'
                className='btn btn-orange--extra fs-14 lh-17 rounded-2 text-none fw-600 mt-4 mt-lg-0 buttonMD'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'O Plano de Celular do Inter tem a melhor rede móvel do Brasil',
                    element_action: 'click button',
                    element_name: 'Consultar cobertura Vivo',
                    redirect_url: 'https://mapadecobertura.vivo.com.br/',
                  })
                }}
              >
                Consultar cobertura Vivo
              </a>
            </div>
          </div>
          <div className='d-none d-md-block col-md-6 col-lg-5'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banner-cobertura-plano-de-celular/image.webp'
              altDescription='Mapa de cobertura ilustrativo.'
              arrayNumbers={[ 312, 312, 456, 456 ]}
              className='float-md-right'
            />
          </div>
        </div>
      </div>
    </Container>
  )
}
