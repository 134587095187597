import React, { useEffect, useState } from 'react'
import { FormFeedbackMessageBlockDiv } from './style'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

type MesssageBlockType = {
  message: string;
  description?: string;
}

type FormFeedbackMessageBlockTypes = {
  data: {
    sucess: MesssageBlockType;
    error: MesssageBlockType;
  };
  error: boolean;
  sent: boolean;
}

const FormFeedbackMessageBlock = ({ data, error, sent }: FormFeedbackMessageBlockTypes) => {
  const [ feedbackMessage, setFeedbackMessage ] = useState<MesssageBlockType>(data.sucess)
  const [ feedbackCard, setFeedbackCard ] = useState< 'sucess' | 'error' >('sucess')
  const [ icon, setIcon ] = useState< 'checkfill' | 'clear' >('clear')
  const haveFeedback = error || sent

  useEffect(() => {
    if (error) {
      setFeedbackMessage(data.error)
      setFeedbackCard('error')
      setIcon('clear')
    } else {
      setFeedbackMessage(data.sucess)
      setFeedbackCard('sucess')
      setIcon('checkfill')
    }
  }, [ error, sent ])

  return (
    <>
      {haveFeedback && (
        <FormFeedbackMessageBlockDiv status={feedbackCard} className='d-flex align-items-center p-3'>
          <div>
            <OrangeDsIcon icon={icon} size='MD' color={icon === 'checkfill' ? '#00AA4F' : '#F56A50'} />
          </div>
          <div className='ml-3'>
            <h4 className='fs-12 lh-15 fw-700 text-grayscale--500 mb-0'>{feedbackMessage?.message}</h4>
            {feedbackMessage.description && <p className='fs-12 lh-15 fw-400 text-grayscale--500 mb-0 mt-1'>{feedbackMessage.description}</p>}
          </div>
        </FormFeedbackMessageBlockDiv>
      )}
    </>
  )
}

export default FormFeedbackMessageBlock
