import React, { useState, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'
import FormFeedbackMessageBlock from 'src/components/FormFeedbackMessageBlock'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import feedbackMessage from './assets/data/data.json'
import { Container, CloseButton, ButtonDiv, FeedbackMessageDiv } from './style'
import { sendDataLayerEvent, sendCDPFormData } from 'src/shared/helpers'

type IntercelRightFormProps = {
  closeModal: () => void;
}

interface IFormValues {
  nome: string;
  email: string;
  celular: string;
  cpf: string;
  dataNascimento: string;
}

function IntercelRightForm ({ closeModal }: IntercelRightFormProps) {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ loading, setLoading ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ sent, setSent ] = useState(false)

  const sendFormOpenAccount = async (data: IFormValues) => {
    setLoading(true)

    const formDataBroker = {
      nome: data.nome,
      cpfCnpj: data.cpf.replace(/\D/g, ''),
      email: data.email,
      campanha: 'Novo Intercel',
      aceite: accept,
    }

    try {
        await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formDataBroker ], {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        setLoading(false)
        sendCDPFormData({ formName: 'Formulario Intercel', email: data.email, cpf: data.cpf.replace(/\D/g, '') })
        sendDataLayerEvent('form_submitted')
        setError(false)
        setSent(true)
    } catch (e) {
      setSent(false)
      setError(true)
      setLoading(false)
    }
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <>
      <Container isModal={closeModal} className='d-flex align-items-md-center'>
        {closeButton}
        <div className='row'>
          <div className='col-12 mb-4'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fw-600 text-grayscale--500 mb-3 mb-md-4 mt-4 mt-md-0'>
              Conheça com exclusividade o <span className='d-block d-md-inline'>+ Limite com Inter Cel</span>
            </h2>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400 mb-0'>Se interessou pelo plano + Limite com Inter Cel? Então informe seus dados e seja uma das primeiras pessoas a receber informações sobre como contratar essa novidade!</p>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendFormOpenAccount)}>
              <div className='row body-form'>
                <div className='col-12 d-flex flex-column mb-3 mb-md-4 pb-1'>
                  <label htmlFor='nome' className='fs-14 lh-16 fw-400'>Nome completo</label>
                  <input
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome completo'
                  />
                  {errors.nome && <p className='fs-12 text-red--base mb-0 fw-600 text-right'>{errors.nome.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column mb-3 mb-md-4 pb-1'>
                  <label htmlFor='cpf' className='fs-14 lh-16 fw-400'>CPF</label>
                  <input
                    ref={register({
                      required: 'Digite um CPF válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder='Digite seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  <p className='fs-14 lh-16 fw-400 text-right mb-0'>Digite apenas numeros</p>
                  {errors.cpf && <p className='fs-12 text-red--base mb-0 fw-600 text-right'>{errors.cpf.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column mb-4 mb-md-5 pb-1'>
                  <label htmlFor='email' className='fs-14 lh-16 fw-400'>E-mail</label>
                  <input
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite o seu e-mail'
                  />
                  {errors.email && <p className='fs-12 text-red--base mb-0 fw-600 text-right'>{errors.email.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column mb-3 mb-md-4'>
                  <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pf' />
                </div>
                <FeedbackMessageDiv className='mb-3 mb-md-4 w-100'>
                  <FormFeedbackMessageBlock data={feedbackMessage} error={error} sent={sent} />
                </FeedbackMessageDiv>
                <div className='col-12 text-center pt-md-2 mb-4 mb-md-0'>
                  <ButtonDiv className='d-flex'>
                    <button
                      onClick={closeModal}
                      title='Voltar'
                      className='btn back-button text-orange--extra fs-14 mx-auto text-none'
                    >
                      Voltar
                    </button>
                    <button
                      type='submit' title='Enviar' disabled={!accept || loading}
                      className='btn btn-orange--extra fs-14 rounded-2 mx-auto text-white text-none'
                    >
                      {loading ? 'Enviando...' : 'Enviar'}
                    </button>
                  </ButtonDiv>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default IntercelRightForm
