import React, { useEffect, useState } from 'react'

import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useDomReady from 'src/hooks/window/useDomReady'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import WazeIcon from '../../../assets/images/Waze.png'
import MoovitIcon from '../../../assets/images/Moovit.png'
import HirePlanApp from '../../../assets/images/qr-code-inter-cel.jpg'

import ToolTip from './ToolTip/_ToolTip'

import {
  iconTelegram,
  iconFacebookMessenger,
  iconInstagram,
  iconPinterest,
  iconTikTok,
  iconTinder,
  iconTwitter,
} from '../assets/icons/_index'

import { Card, CardWrapper, CardHeader, CardBody, CardFooter, ToggleDiv, TotalGb } from '../style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IMonthlyPlansCard {
  oneLink: string;
  cardData: {
    bestSeller: boolean;
    bestSellertext: string;
    totalGb: string;
    gbSum: string;
    gbBonusPlus: string;
    monthlyPrice: string;
    cashbackValue: string;
    portabilityGbBonus: number;
    frequencyBonus: string;
    hasSocial: boolean;
    hasRecharge: boolean;
    planDays: number;
    cashback: boolean;
    paymentPeriod: string;
  };
}

const MonthlyPlansCard = ({ cardData, oneLink }: IMonthlyPlansCard) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ totalGb, setTotalGb ] = useState<number>(+cardData.totalGb)
  const [ portabilityGbBonus, setPortabilityGbBonus ] = useState(true)
  const [ sendDatalayerEvent ] = useDataLayer()

  const domReady = useDomReady()

  const ModalInterCelRight = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-24 lh-30"><span class="d-block">Acesse o <span class="text-orange--extra">Super App,</span></span>e contrate o seu plano agora!</span>'
        subtitle='<span class="d-block fs-18 lh-22">Os planos da Inter cel são exclusivos para clientes Inter. Você pode adquiri-los dentro<span class="d-lg-block"> do nosso Super App. Acesse, escolha seu plano e aproveite!</span></span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para
        a loja de aplicativos. Aí é só baixar o app do Inter.'
        qrCode={HirePlanApp}
      />
    </Modal>
  )

  useEffect(() => {
    if (portabilityGbBonus) {
      setTotalGb(totalGb + cardData.portabilityGbBonus)
    } else {
      setTotalGb(+cardData.totalGb)
    }
  }, [ portabilityGbBonus ])

  return (
    <Card bestSeller={cardData.bestSeller} className=''>
      {ModalInterCelRight}
      <CardWrapper bestSeller={cardData.bestSeller}>
        {cardData.bestSeller && (
          <span className='text-white fw-600 fs-24 lh-30'>
            {cardData.bestSellertext}
          </span>)
        }
        <CardHeader>
          <TotalGb triggerAnimation={portabilityGbBonus} className='d-block fw-600 fs-32 lh-50 text-grayscale--500 mb-1 sora'>{totalGb} GB</TotalGb>
          <span className='d-block fs-14 lh-20 text-grayscale--500 sora' dangerouslySetInnerHTML={{ __html: cardData.gbSum }} />
          {portabilityGbBonus && <span className='d-block fs-14 lh-20 text-grayscale--500 sora' dangerouslySetInnerHTML={{ __html: cardData.gbBonusPlus }} />}
          <span className='d-block fw-700 fs-14 lh-17 text-grayscale--500 my-2'>Apps ilimitados:</span>
          <div className='icons-wrapper'>
            <OrangeIcon size='MD' color='#ff7a00' icon='notification-center' />
            <OrangeIcon size='MD' color='#25D366' icon='whatsapp' className='ml-1' />
            <img src={WazeIcon} alt='waze' className='ml-1' />
            <img src={MoovitIcon} alt='moovit' />
          </div>

          {cardData.hasSocial && (
            <>
              <span className='d-block fw-700 fs-14 lh-17 text-grayscale--500 mb-2 mt-3'>Redes sociais do pacote:</span>
              <div className='icons-wrapper'>
                <img src={iconInstagram} alt='instagram icon' className='ml-1' />
                <img src={iconTikTok} alt='Tik Tok icon' className='ml-1' />
                <img src={iconFacebookMessenger} alt='Facebook Messenger icon' className='ml-1' />
                <img src={iconTinder} alt='Tinder icon' className='ml-1' />
                <img src={iconTwitter} alt='Twitter icon' className='ml-1' />
                <img src={iconTelegram} alt='Telegram icon' className='ml-1' />
                <img src={iconPinterest} alt='Pinterest icon' className='ml-1' />
              </div>
            </>
          )}

        </CardHeader>
        <CardBody className=''>
          <ToggleDiv className='body-items mb-4'>
            <div>
              <label className='switch'>
                <input type='checkbox' checked={portabilityGbBonus} onClick={() => setPortabilityGbBonus(!portabilityGbBonus)} />
                <span className='slider round' />
              </label>
            </div>
            <span className='fw-700 fs-14 lh-17 text-grayscale--500 pl-2'>
              {cardData.portabilityGbBonus}GB de bônus {cardData.frequencyBonus} na portabilidade*
              <span className='d-block fs-12 lh-15 fw-400 mt-1'>
                Válido por 12 meses
              </span>
            </span>
          </ToggleDiv>
          <div className='body-items mb-4'>
            <div>
              <OrangeIcon size='SM' color='#00AA4F' icon='check' />
            </div>
            <span className='fw-700 fs-14 lh-17 text-grayscale--500 pl-3'>
              Ligações ilimitadas
              <span className='d-block fs-12 lh-15 fw-400 mt-1'>
                Fixo, celular e DDDs usando 015 + DDD + número
              </span>
            </span>
          </div>
          {cardData.hasRecharge &&
            <div className='body-items mb-4'>
              <div>
                <OrangeIcon size='SM' color='#00AA4F' icon='check' />
              </div>
              <span className='fw-700 fs-14 lh-17 text-grayscale--500 pl-3'>
                1GB de bônus de recarga
                <span className='d-block fs-12 lh-15 fw-400 mt-1'>
                  Válidos por 7 dias
                </span>
              </span>
            </div>}
          <div className='body-items mb-4'>
            <div>
              <OrangeIcon size='SM' color='#00AA4F' icon='check' />
            </div>
            <span className='fw-700 fs-14 lh-17 text-grayscale--500 pl-3'>
              Caixa postal
            </span>
          </div>
          <div className='body-items mb-4 position-relative'>
            <div>
              <OrangeIcon size='SM' color='#00AA4F' icon='check' />
            </div>
            <span className='fw-700 fs-14 lh-17 text-grayscale--500 pl-3'>
              <div className='d-flex align-items-center'>
                <span>Plano válido por {cardData.planDays} dias</span>
                <span className='cursor-pointer tooltip--svg ml-2 ml-lg-0'>
                  <ToolTip />
                </span>
              </div>
              <span className='d-block fs-12 lh-15 fw-400 mt-1'>
                <span className='d-block'>Ative a renovação automática</span>
                {cardData.cashback && <span className='text-green--500 fw-700'>receba mais cashback</span>}
              </span>
            </span>
          </div>
        </CardBody>
        <CardFooter className='pb-3'>
          <div>
            <span className='fw-600 fs-40 lh-50 text-grayscale--500 d-block mb-0 sora'>{cardData.monthlyPrice}</span>
            <span className='fw-400 fs-16 lh-20 text-left ml-1 text-grayscale--400'>{cardData.paymentPeriod}</span>
          </div>
          <span className='success fw-700 fs-14 lh-17 d-block mb-3'>
            {cardData.cashbackValue && <OrangeIcon size='MD' color='#00AA4F' icon='cashback' />}
            {cardData.cashbackValue && cardData.cashbackValue}
            {cardData.cashbackValue && <span className='fw-400'> de cashback</span>}
          </span>
          <div className='d-md-none'>
            <a
              href={oneLink}
              className='btn btn--lg btn-orange--extra fs-14 lh-17 fw-700 rounded-2 text-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  section_name: 'Escolha seu plano',
                  element_action: 'click button',
                  element_name: 'Contratar pelo app',
                  redirect_url: oneLink,
                })
              }}
            >
              Contratar pelo app
            </a>
          </div>
          <button
            onClick={() => {
              setIsOpen(true)
              sendDatalayerEvent({
                section: 'dobra_03',
                section_name: 'Escolha seu plano',
                element_action: 'click button',
                element_name: 'Contratar pelo app',
              })
            }}
            className='btn btn--lg btn-orange--extra fs-14 lh-17 fw-700 rounded-2 d-none d-md-block text-none'
          >
            Contratar pelo app
          </button>
        </CardFooter>
      </CardWrapper>
    </Card>
  )
}

export default MonthlyPlansCard
