import styled, { css } from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'

import { StepsProps } from './types'

import StepOneMobile from '../../assets/images/portabilidade-intercel-mobile.png'
import StepTwoMobile from '../../assets/images/step-two-mobile.png'
import StepThreeMobile from '../../assets/images/step-three-mobile.png'
import StepFourMobile from '../../assets/images/step-four-mobile.png'

export const Container = styled.div`
  position: relative;

  .sora {
    font-family: 'Sora'
  }
`

export const DescriptionContainer = styled.section`
  min-height: 335px;
  position: relative;
  align-items: initial;

  @media ${device.tablet} {
    min-height: 293px;
  }
`
export const StepsContainer = styled.section<StepsProps>`
  min-height: 670px;
  align-items: initial;

  ${({ step }: StepsProps) => step === 1 && css`
    background: ${grayscale[100]} url(${StepOneMobile}) no-repeat bottom 0 center;
  `}
  ${({ step }: StepsProps) => step === 2 && css`
    background: ${grayscale[100]} url(${StepTwoMobile}) no-repeat bottom -20px center;
  `}
  ${({ step }: StepsProps) => step === 3 && css`
    background: ${grayscale[100]} url(${StepThreeMobile}) no-repeat bottom -20px center;
  `}
  ${({ step }: StepsProps) => step === 4 && css`
    background: ${grayscale[100]} url(${StepFourMobile}) no-repeat bottom -30px center;
  `}

  @media ${device.tablet} {
  min-height: 292px;
  background: ${grayscale[100]}
  }

  .container {
    position: relative;
  }
`

export const StepsWizzard = styled.section`
  min-height: 110px;
  position: relative;
  align-items: initial;
  background: ${grayscale[100]};

  .more {
    transform: rotate(90deg);
  }

  .inactive {
    opacity: 0.3;
  }

  span {
    cursor: pointer;
  }

  @media ${device.tablet} {
    min-height: 0;
  }

  @media ${device.desktopLG} {
    min-height: 60px;
  }

  @media ${device.desktopXL} {
    min-height: 30px;
  }
`

export const ButtonContainer = styled.section`
  min-height: 139px;
  position: relative;
  align-items: initial;
  background: ${grayscale[100]};

  button {
    height: 48px;
  }
`

export const Phone = styled.img`
  position: absolute;
  right: 0;
  top: 245px;

  @media ${device.tablet} {
    top: 230px;
    width: 337px;
  }

  @media ${device.desktopLG} {
    top: 200px;
    width: 375px;
    height: 421px;
  }

  @media ${device.desktopXL} {
    top: 105px;
    right: 115px;
    width: auto;
    height: auto;
  }
`

export const ScrollLink = styled.p`
  position: absolute;
  bottom: 30px;
  left: 50%;
  text-align: center;
  line-height: 1.67;
  transform: translateX(-50%);
  width: auto;
`
export const Dot = styled.div<StepsProps>`
  width: 5px;
  height: 5px;
  background: ${orange.extra};
  border-radius: 50%;
  opacity: 0.3;

  ${({ active }: StepsProps) => active && css`
    opacity: 1;
  `}

  & + & {
    margin-left: 5px;
  }
`

export const Disclaimer = styled.p<{ step?: number }>`
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${grayscale[400]};


  ${({ step }: any) => step === 1 && css`
      margin-top: -10px;
  `}
  ${({ step }: any) => step === 4 && css`
      margin-top: 10px;
  `}

  @media ${device.tablet} {
  position: absolute;
  right: 98px;
  bottom: 100px;
  }

  @media ${device.desktopLG} {
  right: 113px;
  bottom: 111px;
  ${({ step }: any) => step > 1 && css`
    bottom: 130px;
  `}

  }

  @media ${device.desktopXL} {
    right: 304px;
    bottom: 117px;
    font-size: 12px;
     ${({ step }: any) => step > 1 && css`
    bottom: 56px;
  `}
  }
`
