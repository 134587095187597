import React from 'react'

const ThirdStep = () => {
  return (
    <>
      <span className='fw-600 fs-24 lh-30 text-grayscale--500 mb-4 d-block sora'>
        3 - Informe o número de telefone
      </span>
      <span className='text-grayscale--500 fs-18 lh-22 fs-lg-18 lh-lg-22 fw-400 mr-n1 mb-4 d-block'>
        Digite seu número do celular com o código de área. Em seguida, selecione o botão
        <span className='fw-700'> “Solicitar portabilidade”. </span>
      </span>
    </>
  )
}

export default ThirdStep
