import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, grayscale, white } from 'src/styles/colors'

export const Container = styled.section`
  @media ${device.tablet} {
    min-height: 554px;
    background: ${orange.extra};
  }

  a {
    align-items: center;
    height: 48px;
    padding-bottom: 0;
    padding-top: 0;

    @media ${device.tablet} {
      max-width: 261px;
    }
  }

  .sora {
    font-family: 'Sora';
  }

  .whiteMD {
    @media ${device.tablet} {
      color: ${grayscale[100]}
    }
  }

  .buttonMD {
    @media ${device.tablet} {
      background: ${white};
      color: ${orange.extra};
    }
  }
`
