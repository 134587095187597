import React, { useState } from 'react'

import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useDomReady from 'src/hooks/window/useDomReady'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { SectionWrapper, Card } from './style'
import HirePlanApp from '../../assets/images/qr-code-inter-cel.jpg'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type OneLinkType = {
  oneLink: string;
}

export const VoceNoControle = ({ oneLink }: OneLinkType) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const ModalInterCelRight = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-24 lh-30"><span class="d-block">Acesse o <span class="text-orange--extra">Super App,</span></span>e contrate o seu plano agora!</span>'
        subtitle='<span class="d-block fs-18 lh-22">Os planos de celular do Inter são exclusivos para clientes Inter. Você pode adquiri-los dentro<span class="d-lg-block"> do nosso Super App. Acesse, escolha seu plano e aproveite!</span></span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para
        a loja de aplicativos. Aí é só baixar o app do Inter.'
        qrCode={HirePlanApp}
      />
    </Modal>
  )
  return (
    <SectionWrapper className='py-5'>
      {ModalInterCelRight}
      <div className='container pt-md-4'>
        <div className='d-lg-flex flex-lg-row order-md-1'>
          <div className='row justify-content-between justify-content-lg-start col-lg-6 mr-lg-4 pt-lg-5'>
            <div className='col-12 col-lg-9 col-xl-10 text-md-center text-lg-left pt-lg-5'>
              <h2 className='fw-600 fs-32 lh-40 fs-xl-48 lh-xl-60 text-grayscale--500 d-block sora mb-3 mb-lg-4 pr-xl-5'>Você no <span className='d-xl-block'> controle de </span>tudo</h2>
              <span className='d-none d-xl-block fw-400 fs-18 lh-22 text-grayscale--500 mb-4 col-xl-12 pl-xl-0 pr-xl-3'>
                Com o Plano de Celular do Inter, quem manda é você! Controle seu plano pelo Super App de forma simples, sem asteriscos.
              </span>
              <button
                onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Você no controle de tudo',
                    element_action: 'click button',
                    element_name: 'Eu quero!',
                  })
                }}
                className='d-none d-lg-block btn btn--lg btn-orange--extra fs-14 lh-17 fw-700 rounded-2 mt-lg-4 text-none mx-md-auto ml-xl-0'
              >
                Eu quero!
              </button>
            </div>
          </div>
          <div className='row pt-lg-5'>
            <div className='col-12 col-md-4 col-lg-6 order-md-3 order-lg-2'>
              <Card className='d-none d-md-block'>
                <OrangeIcon size='MD' icon='smartphones' color='#FF7A00' className='mb-3' />
                <span className='fw-600 fs-16 lh-20 fs-xl-20 lh-xl-25 sora d-block pt-4 text-grayscale--400'>Acompanhe seu consumo de internet e minutos</span>
              </Card>
            </div>
            <div className='col-12 col-md-4 col-lg-6 d-none d-md-block order-md-5 order-lg-3 mt-md-4 mt-lg-0'>
              <Card className='d-none d-md-block'>
                <OrangeIcon size='MD' icon='check-arrows' color='#FF7A00' className='mb-4' />
                <span className='fw-600 fs-16 lh-20 fs-xl-20 lh-xl-25 sora d-block pt-4 text-grayscale--400'>Troque de plano a qualquer momento. Direto no app!</span>
              </Card>
            </div>
            <div className='col-12 col-md-4 col-lg-6 d-none d-md-block mt-lg-4 order-md-2 order-lg-4'>
              <Card>
                <OrangeIcon size='MD' icon='portability' color='#FF7A00' className='mb-3' />
                <span className='fw-600 fs-16 lh-20 fs-xl-20 lh-xl-25 sora d-block pt-4 text-grayscale--400'>Peça e acompanhe a portabilidade do seu número</span>
              </Card>
            </div>
            <div className='col-12 col-md-4 col-lg-6 d-none d-md-block mt-md-4 order-md-6 order-lg-5'>
              <Card className='d-none d-md-block'>
                <OrangeIcon size='MD' icon='add' color='#FF7A00' className='mb-3' />
                <span className='fw-600 fs-16 lh-20 fs-xl-20 lh-xl-25 sora d-block pt-4 text-grayscale--400'>Contrate pacotes adicionais quantas vezes quiser</span>
              </Card>
            </div>
            <div className='col-12 col-md-4 col-lg-6 d-none d-md-block mt-md-0 mt-lg-4 order-md-4 order-lg-6'>
              <Card className='d-none d-md-block'>
                <OrangeIcon size='MD' icon='plus-arrows' color='#FF7A00' className='mb-3' />
                <span className='fw-600 fs-16 lh-20 fs-xl-20 lh-xl-25 sora d-block pt-4 text-grayscale--400'>Faça recargas de modo fácil e descomplicado</span>
              </Card>
            </div>
            <div className='col-12 col-md-4 col-lg-6 d-none d-md-block mt-md-4 order-md-7'>
              <Card className=''>
                <OrangeIcon size='MD' icon='check' color='#FF7A00' className='mb-3' />
                <span className='fw-600 fs-16 lh-20 fs-xl-20 lh-xl-25 sora d-block pt-4 text-grayscale--400'>Desbloqueie e ative  seu chip só com alguns cliques</span>
              </Card>
            </div>
          </div>
        </div>
        <div className='d-lg-none col-12'>
          <div className='d-md-none'>
            <a
              href={oneLink}
              className='btn btn--lg btn-orange--extra fs-14 lh-17 fw-700 rounded-2 text-none mt-4 '
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  section_name: 'Você no controle de tudo',
                  element_action: 'click button',
                  element_name: 'Eu quero!',
                  redirect_url: oneLink,
                })
              }}
            >
              Eu quero!
            </a>
          </div>
          <button
            onClick={() => {
              setIsOpen(true)
              sendDatalayerEvent({
                section: 'dobra_05',
                section_name: 'Você no controle de tudo',
                element_action: 'click button',
                element_name: 'Eu quero!',
              })
            }}
            className='btn btn--lg btn-orange--extra fs-14 lh-17 fw-700 rounded-2 mt-4 mt-md-5 d-none d-md-block text-none mx-md-auto'
          >
            Eu quero!
          </button>
        </div>
      </div>
    </SectionWrapper>
  )
}
