import React, { useState, useEffect } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import QueryString from 'query-string'
import { useLocation } from '@reach/router'
import { Button } from '@interco/inter-ui/components/Button'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import IntercelRightForm from 'src/components/UI/Forms/IntercelRightForm'
import Hero from './sections/hero/_index'
import { Cobertura } from './sections/Cobertura/_index'
import { EscolhaPlano } from './sections/EscolhaPlano/_index'
import { VoceNoControle } from './sections/VoceNoControle/_index'
import SeuChipNaSuaCasa from './sections/SeuChipNaSuaCasa/_index'
import { Portabilidade } from './sections/Portabilidade/_index'
import FAQ from './sections/Faq/_index'

import pageContext from './pageContext.json'

import { Wrapper, SnackBarContainer, QRCodeContainer, CouponText, CouponTitle } from './style'
import { orange } from 'src/styles/colors'
import { initTheme, Theme } from '@interco/inter-ui'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_SM } from 'src/styles/breakpoints'
import qrCode from './assets/images/qr-code-coupon.png'
import { CollapsibleSnackBar } from '@interco/inter-ui/components/CollapsibleSnackBar'
import Coupon from '@interco/icons/build-v4/orangeds/MD/coupon'

const CouponSnackBar = () => {
  const [ isHidden, setIsHidden ] = useState(false)
  const width = useWidth()
  useEffect(() => {
    initTheme(Theme.PF)
  })

  const SnackBarTitle = (
    <CouponTitle>
      <strong>Você ganhou 1 <strong style={{ color: orange.extra, marginLeft: '3px' }}>CUPOM</strong></strong>
    </CouponTitle>
  )

  const SnackBarContent = () => (
    <>
      <CouponText>
        <span>
          R$20 OFF no 1° mês pra garantir o plano de R$30. Use o cupom
          <strong>NOVO30.</strong>
        </span>
      </CouponText>
      {width <= WIDTH_SM ? (
        <Button
          as='a'
          href='https://intergo.app/3c1d4926'
          fullWidth
          style={{ height: '32px' }}
        >
          Pegar cupom
        </Button>
      ) : (
        <QRCodeContainer>
          <img height={144} src={qrCode} alt='Oferta exclusiva no Super App Inter. Leia o QR Code e seja direcionado.' />
          <p>Oferta exclusiva no Super App Inter.<br />Leia o QR Code e seja direcionado.</p>
        </QRCodeContainer>
      )
      }
    </>
  )

  return !isHidden && (
    <SnackBarContainer>
      <CollapsibleSnackBar
        title={SnackBarTitle}
        ariaLabel='Você ganhou 1 CUPOM'
        icon={<Coupon width={24} height={24} color='#fff' />}
        onCloseListener={() => setIsHidden(true)}
      >
        <SnackBarContent />
      </CollapsibleSnackBar>
    </SnackBarContainer>
  )
}

const Intercel = () => {
  const [ modalIntercelRightForm, setModalIntercelRightForm ] = useState(false)
  const domReady = useDomReady()
  const location = useLocation()

  const oneLink = 'https://intergo.app/bc58d403'

  const queryString = QueryString.parse(location.href)

  useEffect(() => {
    if (queryString.modal === 'open-intercel-modal') {
      setModalIntercelRightForm(true)
      window.history.pushState('', '', window.location.href.replace('?&modal=open-intercel-modal', ''))
    }
  }, [])

  const OpenAccoutForm = domReady && (
    <Modal isModalOpen={modalIntercelRightForm} setIsModalOpen={setModalIntercelRightForm} locationToRender={document.body}>
      <IntercelRightForm closeModal={() => setModalIntercelRightForm(false)} />
    </Modal>
  )

  return (
    <Wrapper>
      {OpenAccoutForm}
      <Layout pageContext={pageContext}>
        <Hero />
        <Cobertura />
        <EscolhaPlano oneLink={oneLink} />
        <SeuChipNaSuaCasa />
        <VoceNoControle oneLink={oneLink} />
        <Portabilidade oneLink={oneLink} />
        <FAQ faq={pageContext.structuredData.faqTab} />
        <CouponSnackBar />
      </Layout>
    </Wrapper>
  )
}

export default Intercel
