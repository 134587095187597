import React from 'react'

const FourthStep = () => {
  return (
    <>
      <span className='fw-600 fs-24 lh-30 text-grayscale--500 mb-4 d-block sora'>
        4 - Pronto! Agora é só acompanhar a solicitação pelo Super App
      </span>
      <span className='text-grayscale--500 fs-18 lh-22 fs-lg-18 lh-lg-22 fw-400 mr-n1 mb-4 d-block'>
        A portabilidade pro plano de celular do Inter acontece em até
        <span className='fw-700'> três dias </span>
        úteis. Você pode acompanhar o andamento da sua solicitação pelo nosso Super App.
      </span>
    </>
  )
}

export default FourthStep
