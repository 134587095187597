import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Container = styled.section`
  .sora {
    font-family: 'Sora';
  }
  @media ${device.desktopLG} {
   min-height: 479px;
  }
  @media ${device.desktopXL} {
   min-height: 587px;
  }
`
export const Card = styled.div`
  max-width: 100%;
  width: 100%;

  .cursor {
    cursor: pointer;
  }

  @media ${device.desktopLG} {
    max-width: 296px;
    height: 150px;
  }
  @media ${device.desktopXL} {
    max-width: 361px;
    max-height: 140px;
    margin-right: 26px;

    &:last-child {
      margin-right: 0;
    }
  }
`
