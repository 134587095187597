import styled, { css } from 'styled-components'
import { device, breakpoints } from 'src/styles/breakpoints'
import { grayscale, white, orange } from 'src/styles/colors'

import { PlansProps, TotalGbType } from './types'

export const ContentHeader = styled.section`
  min-height: 100vh;
  position: relative;
  align-items: initial;

  .react-multi-carousel-track {
    padding: 0;

    @media ${device.tablet} {
      display: flex;
      align-items: end;
    }
  }

  .react-multi-carousel-dot--active {
    button { 
      background: #FF7A00; 
    }
  }
  
  .react-multi-carousel-dot {
    button { 
      background: #FF7A00;
      opacity: 0.5;
    }
  }

  .react-multi-carousel-list {
    padding: 0 0 40px 0;
  }

  @media ${device.tablet} {
    align-items: center;
  }

  .btn {
    height: 48px;
  }

  .sora {
    font-family: 'Sora';
  }

  .tabs {
    @media (min-width: ${breakpoints.md}) {
      min-height: 531px;
    }

    ul {
      justify-content: center;
      margin-bottom: 24px;

      li {
        &:first-child {
          margin-right: 12px;
        }
        &:last-child {
          margin-right: 12px;
        }

        button {
          width: 156px;
          text-align: center;

          @media (min-width: ${breakpoints.md}) {
            width: 348px;
          }
          @media (min-width: ${breakpoints.md}) {
            width: 270px;
          }
        }
      }
    }
  }
`

export const Carousel = styled.div`
  @media (min-width: ${breakpoints.md}) {
    position: relative;
    top: -40px;
  }

  @media (min-width: ${breakpoints.xl}) {
    position: relative;
    top: 10px;
  }
`

export const Card = styled.div<PlansProps>`
  position: relative;
  margin-top: 48px;
  min-height: 684px;
  border-radius: 16px;
  padding: 16px 4px 4px;

  ${({ bestSeller }: PlansProps) => bestSeller && css`
    background: ${orange.extra};
  `}

  .text-green--500 {
    color: #00AA4F;
  }

  .tooltip--svg {
    svg {
      margin: 0 !important;
    }
  }
`

export const CardWrapper = styled.div<PlansProps>`
  box-shadow: 0px 4px 20px rgba(182, 172, 164, 0.25);
  border-radius: 16px;
  margin-top: 28px;

  ${({ bestSeller }: PlansProps) => bestSeller && css`
      // box-shadow: 0px 4px 40px rgba(255, 122, 0, 0.25);
      margin-top: 0;
  `}

  & + * {
    margin-left: 20px;
  }

  .text-line-through {
    text-decoration: line-through;
  }
`

export const CardHeader = styled.div`
  background: ${white};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom: 1px solid ${grayscale[200]};
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 10px;

  @media ${device.tablet} {
    height: auto;
  }

  @media ${device.desktopLG} {
    height: 280px;
  }

  .icons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const CardBody = styled.div`
  background: ${white};
  border-bottom: 1px solid ${grayscale[200]};
  padding: 25px 16px 0 10px;
  text-align: left;
  
  @media ${device.desktopLG} {
    height: 400px;
  }

  @media ${device.desktopXL} {
    padding: 25px 32px 0 32px;
    height: 440px;
  }

  .body-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin: 0 6px 6px;
    }
  }
`

export const BannerGourmet = styled.div`
  padding: 4px 8px;
  background: #07605B;
  color: ${grayscale[100]};
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;

  @media ${device.desktopXL} {
    margin-top: -23px;
  }
`

export const CardFooter = styled.div`
  background: rgba(245, 246, 250);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 25px 16px;
  border: 1px solid ${grayscale[200]};

  @media ${device.tablet} {
    height: 198px;
  }

  @media ${device.desktopXL} {
    height: 202px;
  }

  .success {
    color: #00AA4F;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .old-price {
    font-family: 'Sora';
    text-decoration: line-through;
  }
`

export const ToggleDiv = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #FF7A00;
  }

  /* input:focus + .slider {
    box-shadow: 0 0 1px #FF7A00;
  } */

  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`

export const TotalGb = styled.span<TotalGbType>`
  ${({ triggerAnimation }: TotalGbType) => triggerAnimation && css`
    animation: highlightText .5s ease-in-out;
  `}

  @keyframes highlightText {
    0% {
      color: ${grayscale[500]};
    }
    50% {
      color: ${orange.extra};
    }
    100% {
      color: ${grayscale[500]};
    }
  }
`
