import React, { useState } from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useDomReady from 'src/hooks/window/useDomReady'

import StepOneDesktop from '../../assets/images/portabilidade-intercel-desktop.png'

import StepTwoMobile from '../../assets/images/step-two-mobile.png'
import StepTwoDesktopLG from '../../assets/images/step-two-desktop-lg.png'
import StepTwoDesktopXL from '../../assets/images/step-two-desktop-xl.png'

import StepThreeMobile from '../../assets/images/step-three-mobile.png'
import StepThreeDesktopLG from '../../assets/images/step-three-desktop-lg.png'
import StepThreeDesktopXL from '../../assets/images/step-three-desktop-xl.png'

import StepFourMobile from '../../assets/images/step-four-mobile.png'
import StepFourDesktopLG from '../../assets/images/step-four-desktop-lg.png'
import StepFourDesktopXL from '../../assets/images/step-four-desktop-xl.png'
import HirePlanApp from '../../assets/images/qr-code-inter-cel.jpg'

import FirstStep from './Steps/_FirstStep'
import SecondStep from './Steps/_SecondStep'
import ThirdStep from './Steps/_ThirdStep'
import FourthStep from './Steps/_FourthStep'

import { Container, DescriptionContainer, StepsContainer, StepsWizzard, ButtonContainer, Phone, Dot } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type Props = {
  oneLink: string;
}

export const Portabilidade = ({ oneLink }: Props) => {
  const [ step, setStep ] = useState(1)
  const [ isOpen, setIsOpen ] = useState(false)
  const steps = 4
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const ModalInterCelRight = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-24 lh-30"><span class="d-block">Acesse o <span class="text-orange--extra">Super App,</span></span>e contrate o seu plano agora!</span>'
        subtitle='<span class="d-block fs-18 lh-22">Os planos da Inter cel são exclusivos para clientes Inter. Você pode adquiri-los dentro<span class="d-lg-block"> do nosso Super App. Acesse, escolha seu plano e aproveite!</span></span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para
        a loja de aplicativos. Aí é só baixar o app do Inter.'
        qrCode={HirePlanApp}
      />
    </Modal>
  )

  const nextStep = () => {
    if (step < steps) {
      setStep(step + 1)
    }
  }

  const previousStep = () => {
    if (step > 1) {
      setStep(step - 1)
    }
  }

  const dots = () => {
    const dots = []

    for (let i = 1; i <= steps; i += 1) {
      dots.push(<Dot active={i === step} key={`phone-plan-${i}`} />)
    }
    return dots
  }

  const showSteps = () => {
    switch (step) {
      case 1:
        return <FirstStep />
      case 2:
        return <SecondStep />
      case 3:
        return <ThirdStep />
      case 4:
        return <FourthStep />
      default:
        return <FirstStep />
    }
  }

  return (
    <Container>
      {ModalInterCelRight}
      <DescriptionContainer className='py-5 d-flex justify-content-between bg-orange--extra'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-12 col-md-11 col-lg-12 col-xl-7 px-0'>
              <h2 className='fw-600 fs-24 fs-lg-32 lh-30 lh-lg-40 text-grayscale--100 mb-4 d-block sora'>
                Pedir portabilidade para o Plano de Celular do Inter é muito fácil
              </h2>
              <div className='col-12 col-lg-7 pl-0 pr-lg-0 col-xl-10 px-0 pr-md-3 pr-xl-4'>
                <span className='text-grayscale--100 fs-18 lh-22 fw-400 d-block'>
                  Primeiro, você recebe seu chip e faz o desbloqueio no seu celular. <span className='fw-700'>Depois, é só solicitar a portabilidade do número que você quer trazer para o Inter!</span> Ao fazer a portabilidade e renovar seu plano mensalmente, você ganha até 5GB de bônus por 12 meses! ¹
                  <span className='d-lg-block pt-lg-4'>
                    Se tiver dúvidas, confira o passo a passo a seguir.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </DescriptionContainer>
      <StepsContainer step={step} className='py-5 pb-md-0 d-flex justify-content-between bg-grayscale--100'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-12 col-md-7 col-lg-6 col-xl-5 pr-md-4 pr-lg-2 pt-xl-5'>
              {showSteps()}
            </div>
          </div>
        </div>
      </StepsContainer>
      <StepsWizzard className='pt-5 pt-md-0 mt-md-n4 mt-lg-n3 d-flex justify-content-between bg-grayscale--100'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6 col-xl-5 d-flex align-items-center justify-content-between'>
              <span onClick={previousStep}>
                <OrangeIcon size='MD' color='#ff7a00' icon='arrow-left' className={step <= 1 ? 'inactive' : ''} />
              </span>
              <div className='d-flex'>
                {dots()}
              </div>
              <span onClick={nextStep}>
                <OrangeIcon size='MD' color='#ff7a00' icon='arrow-right' className={step >= steps ? 'inactive' : ''} />
              </span>
            </div>
          </div>
        </div>
      </StepsWizzard>
      <ButtonContainer className='pt-5 d-none d-lg-block'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-12 d-flex align-items-center justify-content-center '
              onClick={() => {
                setIsOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_06',
                  section_name: 'Pedir portabilidade para o Plano de Celular do Inter é muito fácil',
                  element_action: 'click button',
                  element_name: 'Fazer portabilidade',
                })
              }}
            >
              <button className='btn btn--lg btn-orange--extra fs-14 lh-17 fw-700 rounded-2 d-block text-none'>
                Fazer portabilidade
              </button>
            </div>

          </div>
        </div>
      </ButtonContainer>
      <ButtonContainer className='d-block d-lg-none'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-12 d-flex align-items-center justify-content-center '
            >
              <a href={oneLink} className='btn btn--lg btn-orange--extra fs-14 lh-17 fw-700 rounded-2 d-block text-none' style={{ paddingTop: 13 }}>
                Fazer portabilidade
              </a>
            </div>
          </div>
        </div>
      </ButtonContainer>
      {step === 1 && (
        <>
          <Phone className='d-none d-md-block d-lg-none' src={StepOneDesktop} />
          <Phone className='d-none d-lg-block d-xl-none' src={StepOneDesktop} />
          <Phone className='d-none d-xl-block' src={StepOneDesktop} />
        </>
      )}
      {step === 2 && (
        <>
          <Phone className='d-none d-md-block d-lg-none' src={StepTwoMobile} />
          <Phone className='d-none d-lg-block d-xl-none' src={StepTwoDesktopLG} />
          <Phone className='d-none d-xl-block' src={StepTwoDesktopXL} />
        </>
      )}
      {step === 3 && (
        <>
          <Phone className='d-none d-md-block d-lg-none' src={StepThreeMobile} />
          <Phone className='d-none d-lg-block d-xl-none' src={StepThreeDesktopLG} />
          <Phone className='d-none d-xl-block' src={StepThreeDesktopXL} />
        </>
      )}
      {step === 4 && (
        <>
          <Phone className='d-none d-md-block d-lg-none' src={StepFourMobile} />
          <Phone className='d-none d-lg-block d-xl-none' src={StepFourDesktopLG} />
          <Phone className='d-none d-xl-block' src={StepFourDesktopXL} />
        </>
      )}
    </Container>
  )
}
