import React, { useState } from 'react'

import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useDomReady from 'src/hooks/window/useDomReady'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Container, Card } from './style'
import HirePlanApp from '../../assets/images/qr-code-inter-cel.jpg'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const SeuChipNaSuaCasa = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const ModalInterCelRight = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-24 lh-30"><span class="d-block">Acesse o <span class="text-orange--extra">Super App,</span></span>e contrate o seu plano agora!</span>'
        subtitle='<span class="d-block fs-18 lh-22">Os planos de celular do Inter são exclusivos para clientes Inter. Você pode adquiri-los dentro<span class="d-lg-block"> do nosso Super App. Acesse, escolha seu plano e aproveite!</span></span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para
        a loja de aplicativos. Aí é só baixar o app do Inter.'
        qrCode={HirePlanApp}
      />
    </Modal>
  )

  return (
    <Container id='seu-chip-na-sua-casa' className='bg-orange--extra d-flex align-items-center py-5'>
      {ModalInterCelRight}
      <div className='container pb-md-3'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 pb-md-4 pb-xl-5 text-center'>
            <h2 className='fs-32 lh-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 text-grayscale--100 fw-600 mb-3 sora'>
              Seu chip chega direto na sua casa
            </h2>
            <p className='fs-18 lh-22 text-grayscale--100 col-lg-9 col-xl-8 mx-lg-auto px-lg-4 px-xl-5'>
              <span className='d-lg-block'>Escolha seu plano pelo app e, em alguns dias, seu chip já chega na sua casa. Você só precisa ativar seu chip e começar a usar!</span>
            </p>
          </div>
          <div className='col-lg-4'>
            <Card className='bg-white rounded-4 p-4 mt-4 px-lg-3 pt-lg-3'>
              <div className='d-flex'>
                <div><OrangeIcon size='MD' icon='smartphones' color='#161616' /></div>
                <div className='ml-2 pt-1'>
                  <span className='fs-18 lh-22 text-grayscale--500 fw-700 mb-1'>1. Acesse o app</span>
                </div>
              </div>
              <div className='col pt-3 mt-1 px-0'>
                <p className='fs-16 lh-19 text-grayscale--400 mb-0'>
                  No Super App do Inter, procure por Plano de Celular no menu.
                </p>
              </div>
            </Card>
          </div>

          <div className='col-lg-4'>
            <Card className='bg-white rounded-4 p-4 my-3 mt-lg-3 mb-lg-0 px-lg-3 pt-lg-3'>
              <div className='d-flex'>
                <div><OrangeIcon size='MD' icon='check' color='#161616' /></div>
                <div className='ml-2 pt-1'>
                  <span className='fs-18 lh-22 text-grayscale--500 fw-700 mb-1'>2. Escolha seu plano</span>
                </div>
              </div>
              <div className='col pt-3 mt-1 px-0'>
                <p className='fs-16 lh-19 text-grayscale--400 mb-0'>
                  Entre as 3 opções de planos, selecione a que melhor te atende.
                </p>
              </div>
            </Card>
          </div>
          <div className='col-lg-4'>
            <Card className='bg-white rounded-4 p-4 mt-lg-3 mb-lg-0 px-lg-3 pt-lg-3'>
              <div className='d-flex'>
                <div><OrangeIcon size='MD' icon='shipping' color='#161616' /></div>
                <div className='ml-2 pt-1'>
                  <span className='fs-18 lh-22 text-grayscale--500 fw-700 mb-1'>3. Seu chip chega em breve</span>
                </div>
              </div>
              <div className='col pt-3 mt-1 px-0'>
                <p className='fs-16 lh-19 text-grayscale--400 mb-0'>
                  Aguarde o prazo de entrega do seu chip para continuar.
                </p>
              </div>
            </Card>
          </div>
          <div className='col-lg-4'>
            <Card className='bg-white rounded-4 p-4 my-3 mt-lg-3 mb-lg-0 px-lg-3 pt-lg-3'>
              <div className='d-flex'>
                <div><OrangeIcon size='MD' icon='security-opened' color='#161616' /></div>
                <div className='ml-2 pt-1'>
                  <span className='fs-18 lh-22 text-grayscale--500 fw-700 mb-1'>4. Desbloqueie seu chip</span>
                </div>
              </div>
              <div className='col pt-3 mt-1 px-0'>
                <p className='fs-16 lh-19 text-grayscale--400 mb-0'>
                  Siga os passos através do app e desbloqueie o seu chip
                </p>
              </div>
            </Card>
          </div>
          <div className='col-lg-4'>
            <Card className='bg-white rounded-4 p-4 mt-lg-3 mb-lg-0 px-lg-3 pt-lg-3'>
              <div className='d-flex'>
                <div><OrangeIcon size='MD' icon='broadcast-mobile' color='#161616' /></div>
                <div className='ml-2 pt-1'>
                  <h3 className='fs-18 lh-22 text-grayscale--500 fw-700 mb-1'>5. Ative o seu chip</h3>
                </div>
              </div>
              <div className='col pt-3 mt-1 px-0'>
                <p className='fs-16 lh-19 text-grayscale--400 mb-0'>
                  Após o desbloqueio, ative o chip e deixe-o pronto para ser usado
                </p>
              </div>
            </Card>
          </div>
          <div className='col-lg-4'>
            <Card className='bg-white rounded-4 p-4 my-3 mt-lg-3 mb-lg-0 px-lg-3 pt-lg-3'>
              <div className='d-flex'>
                <div><OrangeIcon size='MD' icon='portability' color='#161616' /></div>
                <div className='ml-2 pt-1'>
                  <h3 className='fs-18 lh-22 text-grayscale--500 fw-700 mb-1'>6. Faça a portabilidade</h3>
                </div>
              </div>
              <div className='col pt-3 mt-1 px-0'>
                <p
                  className='fs-16 lh-19 text-grayscale--400 mb-0'
                >
                  Traga o seu número para o Inter e ganhe bônus.
                  <span
                    className='text-orange--extra fw-700 fs-18 lh-22 cursor'
                    onClick={() => {
                      setIsOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_04',
                        section_name: 'Seu chip chega direto na sua casa',
                        element_action: 'click button',
                        element_name: 'Saiba Mais',
                      })
                    }}
                  >
                    Saiba Mais
                  </span>
                </p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SeuChipNaSuaCasa
