import styled from 'styled-components'

type cardStatus = {
  status: 'sucess' | 'error';
}

export const FormFeedbackMessageBlockDiv = styled.div`
  background-color: ${(props: cardStatus) => props.status === 'sucess' ? '#E9F8F0' : '#FEF0ED'};;
  border-radius: 8px;
`
