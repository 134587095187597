import React from 'react'

const FirstStep = () => {
  return (
    <>
      <span className='fw-600 fs-24 lh-30 text-grayscale--500 mb-4 d-block sora'>
        1 - Pra começar...
      </span>
      <span className='text-grayscale--500 fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 mr-n1 mb-4 d-block'>
        Acesse o Super App e, no menu Plano de Celular, escolha a opção
        <span className='fw-700'> Portabilidade para o plano de celular do Inter </span>
        no final da página.
      </span>
      <span className='fs-14 lh-17 fw-400 text-grayscale--500 mr-n3 d-block'>
        Ah, se voce for cliente Vivo ou de uma de suas operadoras móveis virtuais,
        precisa pedir a migração para a operadora de celular do Inter que acontece logo após o desbloqueio do chip, antes mesmo de inserir o chip no celular, beleza?
        <span className='pl-md-1 fs-14 lh-17 fw-700 text-orange--extra mb-5 d-none d-md-inline-block d-lg-none'>  Saiba mais sobre migração</span>
      </span>
      <a href='https://ajuda.bancointer.com.br/pt-BR/articles/5538655-o-que-e-portabilidade'><span className='fs-14 lh-17 fw-700 text-orange--extra mb-5 d-block d-md-none d-lg-block'> Saiba mais sobre migração</span></a>
    </>
  )
}

export default FirstStep
