import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`

  h1, h2, h3, h4, h5 {
    font-family: 'Sora';
  }
`

export const SnackBarContainer = styled.div`
  position: sticky;
  max-width: 285px;
  bottom: 90px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 3;

  @media (min-width: ${breakpoints.md }){
    position: fixed;
    right: 4rem;
    left: unset;
    bottom: 56px;
    margin: 0;
  }
`

export const CouponTitle = styled.h6`
  display: flex;
  font-size: 13px;
  flex-grow: 1;
  margin: auto 0;
`

export const CouponText = styled.div`
  padding: 10px 8px 0 8px;
  span {
    line-height: 19px;
    font-size: 16px;
    color: #fff;
    display: inline-block;
    margin: 0 0 24px 0;
    strong {
      color: ${orange.extra};
      margin-left: 4px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    span {
      margin-bottom: 8px;
    }
  }
`

export const QRCodeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    margin-bottom: 16px;
  }
  p {
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #fff;
  }
`
