import React, { useState, useEffect } from 'react'
import { Search, Input, Summary, Circle, SummaryCol, SummaryDetails, Button, TabNames, TabNamesDiv } from './style'
import { FaqProps, FaqTabType, AnswerDataType } from './types'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

const defaultColors = {
  sm: 1,
  md: 1,
  lg: 2,
  xl: 2,
}

const FaqWithTab = ({ color, questionColor, answersColor, answerData, columns, searchBg, id, className, borderColor, placeholderColor }: FaqProps) => {
  const [ faqTopicsList ] = useState<FaqTabType[]>(answerData)
  const [ faqList, setFaqList ] = useState<AnswerDataType[]>(faqTopicsList[0].faq)
  const [ activeTopicName, setActiveTopicName ] = useState<string>(faqTopicsList[0].title)
  const [ hideTabNames, setHideTabNames ] = useState<boolean>(false)
  const [ searchText, setSearchText ] = useState('')

  const cols = {
    ...defaultColors,
    ...columns,
  }

  const returnSelectedTopic = (name: string) => {
    const topicFiltered = faqTopicsList.find((topic: FaqTabType) => topic.title === name)
    if (topicFiltered) {
      setFaqList(topicFiltered.faq)
      setActiveTopicName(topicFiltered.title)
    }
  }

  const returnAllFaqLists = () => {
    const onlyFaqList: AnswerDataType[] = []
    answerData.forEach((item: FaqTabType) => {
      item.faq.forEach((faqItem: AnswerDataType) => {
        onlyFaqList.push(faqItem)
      })
    })
    return onlyFaqList
  }

  useEffect(() => {
    const newList = faqList.map((item: AnswerDataType) => {
      return {
        ...item,
        active: false,
      }
    })
    setFaqList(newList)
  }, [ ])

  const handleClick = (index: number) => {
    const newList = faqList.map((item: AnswerDataType, idx: number) => {
      if (idx === index) {
        return {
          ...item,
          active: !item.active,
        }
      }
      return item
    })
    setFaqList(newList)
  }

  const handlClean = () => {
    setSearchText('')
    setHideTabNames(false)
    setFaqList(faqTopicsList[0].faq)
    returnSelectedTopic(faqTopicsList[0].title)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueInput = event.target.value
    const allFaqAnswers: AnswerDataType[] = returnAllFaqLists()
    setSearchText(valueInput)
    if (valueInput.length >= 3) {
      setHideTabNames(true)
      const valueSplit = valueInput.trim().split(/\s/)
      const valueRegex = new RegExp(`(${valueSplit.join('|')})`, 'gi')
      const filter = allFaqAnswers.filter((item: AnswerDataType) => item.question.match(valueRegex) || item.answer.match(valueRegex))
        .map((item: AnswerDataType) => ({
          active: true,
          question: item.question,
          answer: item.answer.replace(valueRegex, '<strong class="text-orange--extra">$1</strong>'),
        }))
        setFaqList([ ...filter ])
    } else {
      setHideTabNames(false)
      setFaqList(faqTopicsList[0].faq)
      returnSelectedTopic(faqTopicsList[0].title)
    }
  }

  const handleTabNames = (tabName: string) => {
    returnSelectedTopic(tabName)
  }

  const isActiveTopic = (currentTopicName: string, activeTopicName: string) => {
    return currentTopicName === activeTopicName && true
  }

  return (
    <div className={className} id={id}>
      <Search>
        <Input
          type='text'
          placeholder='Pesquisar'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)} value={searchText}
          background={searchBg}
          placeholderColor={placeholderColor}
        />
        {
          searchText === '' ? (
            <OrangeDsIcon icon='search' size='MD' color={color || '#ff7a00'} className='search-icon' />

          ) : (
            <Button onClick={() => handlClean()}>
              <OrangeDsIcon icon='exit' size='MD' color='#ff7a00' />
            </Button>
          )
        }
      </Search>
      <TabNamesDiv className='d-flex flex-wrap'>
        {!hideTabNames && faqTopicsList.map((data: FaqTabType) => (
          <TabNames key={data.title} className='fs-12 lh-14 py-2 px-3' active={isActiveTopic(data.title, activeTopicName)} onClick={() => handleTabNames(data.title)}>{data.title}</TabNames>
        ))}
      </TabNamesDiv>
      <div className='row'>
        {
          faqList.length > 0
            ? faqList.map((item: AnswerDataType, index: number) => (
              <SummaryCol
                className={`col-12 col-sm-${12 / cols.sm} col-md-${12 / cols.md} col-lg-${12 / cols.lg} col-xl-${12 / cols.xl} `}
                key={item.question}
              >
                <Summary borderColor={borderColor} active={item.active}>
                  <div className='header' onClick={() => handleClick(index)}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h4
                        className={`fs-14 lh-17 fs-lg-16 lh-lg-19 pr-4 mb-0 ${questionColor || 'text-grayscale--500'} fw-600`}
                        dangerouslySetInnerHTML={{ __html: item.question }}
                      />
                      <Circle>
                        {
                          item.active ? <OrangeDsIcon icon='chevron-up' color='#ff7a00' size='SM' /> : (
                            <OrangeDsIcon icon='chevron-down' color='#ff7a00' size='SM' />
                          )
                        }
                      </Circle>
                    </div>
                  </div>
                  {
                    item.active && (
                      <SummaryDetails>
                        <div
                          className={`fs-12 lh-15 fs-14 lh-17 ${answersColor || 'text-grayscale--400'} `}
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        />
                      </SummaryDetails>
                    )
                  }
                </Summary>
              </SummaryCol>

            )) : (
              <div className='col-12'>
                <p className={`fs-14 lh-17 fs-lg-16 lh-lg-19  ${questionColor || 'text-grayscale--500'} fw-400`}>
                  Não foi possível encontrar nenhuma pergunta para: {searchText}
                </p>
              </div>
          )
        }
      </div>
    </div>
  )
}

export default FaqWithTab
