import React, { useState } from 'react'

// hooks
import useWidth from 'src/hooks/window/useWidth'
import Help from 'inter-frontend-svgs/lib/orangeds/MD/help'

// Styles
import { grayscale } from 'src/styles/colors'
import { Tooltip, ButtonToolTip } from './style'

const ToolTip = () => {
  const [ open, setOpen ] = useState(false)

  const WIDTH_LG = 1024
  const windowWidth = useWidth(300)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      { windowWidth >= WIDTH_LG
        ? (
          <ButtonToolTip onClick={() => handleClick()}>
            <Help color={grayscale[500]} width={16} height={16} />
          </ButtonToolTip>
          )
        : (
          <ButtonToolTip onClick={() => setOpen(true)}>
            <Help color={grayscale[500]} width={16} height={16} />
          </ButtonToolTip>)
      }

      {open &&
        <Tooltip className='tooltip'>
          <p className='fs-12 lh-15 fw-700 text-white mb-1'>Renovação automática</p>
          <p className='fs-12 lh-15 fw-400 text-white text-left'>Com a renovação automática, o valor do plano será debitado da sua conta ou cobrado em seu cartão todo mês. Você pode desativá-la a qualquer momento.</p>
          <span className='d-block fs-12 lh-15 fw-700 text-orange--extra text-right' onClick={() => setOpen(false)} title='Entendi'>Entendi</span>
        </Tooltip>
      }
    </>
  )
}

export default ToolTip
