import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`


  button {
    height: 48px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 8px !important;
    text-transform: none;
  }

  p {
    @media ${device.desktopLG} {
      margin-bottom: 32px !important;
    }
  }
`
